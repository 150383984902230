import React, { useEffect, useState, useRef } from 'react';
import { Space, Table, Tag, Button, Input, Spin } from 'antd';
import * as facilitiesSlice from '../features/facilities/facilitiesSlice'; 
import { updateSelectedFacilityDetails, fetchFacilityDetails } from '../features/facilities/facilitiesSlice';

import store from "../redux/store"; 
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as unitsSlice from '../features/units/unitsSlice'; 
import * as engrainSlice from '../features/engrain/engrainSlice';

const FacilitiesTable = ({ loading, facilities }) => {
    const [facilityDetailsLoading, setFacilityDetailsLoading] = useState(false);
  
    const [facilitiesData, setFacilitiesData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef();
  
    const navigate = useNavigate();
    const cookie = document.cookie;

    // Utility Functions for ID Padding

    /**
     * Pads the given ID with leading zeros to reach the desired length.
     * @param {number|string} id - The ID to pad.
     * @param {number} length - The desired total length after padding.
     * @returns {string|number} - The padded ID as a string or original ID if falsy.
     */
    const padNSAId = (id, length = 4) => {
      if (id == null || id.toString().trim().length === 0) {
        return id;
      }
      const idStr = id.toString();
      return idStr.padStart(length, '0');
    };

    /**
     * Determines which ID to use and pads it appropriately.
     * @param {number|string|null} originalId - The original ID.
     * @param {number|string|null} nsaId - The NSA ID.
     * @param {number} padLength - The desired padding length.
     * @returns {string|number|null} - The appropriately padded ID.
     */
    const getOriginalId = (originalId, nsaId, padLength = 4) => {
      if (originalId == null && nsaId != null) {
        return padNSAId(nsaId, padLength);
      }
      return padNSAId(originalId, padLength);
    };

    const handleView = async (record) => {
        setFacilityDetailsLoading(true); 
        
        try {
          const facilityDetailsResponse = await store.dispatch(fetchFacilityDetails({ brand: record.brand, id: record.id, cookie: cookie })).unwrap();
          
          store.dispatch(updateSelectedFacilityDetails(facilityDetailsResponse));
          
          await store.dispatch(engrainSlice.fetchUnits());
          await store.dispatch(engrainSlice.fetchFloors());
          await store.dispatch(unitsSlice.fetchUnits(record.id));
      
          setFacilityDetailsLoading(false); 
          navigate(`/facilities/${record.id}`);
        } catch (error) {
          console.error('Error fetching facility details:', error);
          setFacilityDetailsLoading(false);
          // Handle error, e.g., navigate to error page or show error message
        }
      };
      

    
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };

    const handleSearch = (selectedKeys, confirm) => {
      confirm();
      setSearchText(selectedKeys[0]);
    };
    
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        Object.values(record).some(
          item => item ? item.toString().toLowerCase().includes(value.toLowerCase()) : ''
        ),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: text =>
        searchText ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });

    // Updated Sorter Function for 'Store #' Column
    const sortByNSAIdLength = (a, b) => {
      const aLength = a.nsa_id != null ? a.nsa_id.toString().length : 0;
      const bLength = b.nsa_id != null ? b.nsa_id.toString().length : 0;
      return aLength - bLength;
    };

    const columns = [
      {
        title: 'Store #',
        dataIndex: 'nsa_id',
        key: 'nsa_id',
        sorter: sortByNSAIdLength,
        ...getColumnSearchProps('nsa_id'),
        render: (text, record) => padNSAId(record.nsa_id),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.length - b.name.length,
        ...getColumnSearchProps('name'),
      },
      {
        title: 'Brand Name',
        dataIndex: 'brand_name',
        key: 'brand',
        sorter: (a, b) => a.brand_name.length - b.brand_name.length,
        ...getColumnSearchProps('brand_name'),
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        ...getColumnSearchProps('address'),
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        ...getColumnSearchProps('city'),
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        sorter: (a, b) => a.state.length - b.state.length,
        ...getColumnSearchProps('state'),
      },
      {
        title: 'Zip Code',
        dataIndex: 'zip_code',
        key: 'zip_code',
        ...getColumnSearchProps('zip_code'),
      },
      {
        title: 'Email',
        dataIndex: 'email_address',
        key: 'email_address',
        ...getColumnSearchProps('email_address'),
      },
      // {
      //   title: 'Phone Number',
      //   dataIndex: 'phone_number',
      //   key: 'phone_number',
      // },
      
    ];

    return (
      <Table
        onRow={(record, rowIndex) => ({
          onClick: () => { handleView(record) }, // click row
        })}
        dataSource={facilities.map(facility => ({
          ...facility,
          key: facility.id, // Ensure each row has a unique key
        }))}
        columns={columns}
        loading={{ indicator: <Spin />, spinning: loading ? true : false }}
      />
    );
  };

export default FacilitiesTable;
